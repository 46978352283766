import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import useLiveControl from "../controlled/useLiveControl"

import Layout from "../components/layout"
import Seo from "../components/seo"

const HomeOwnerReferral = ({ data }) => {
  const [show, setShow] = useState(false)
  const [message] = useState(false)
  const handleClose = () => setShow(false)
  
  const { baseUrl } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Homeowner Referral"}
        description={
          "Ready to start a home improvement project? Tell your contractor about EnerBank's home improvement payment options and get rewarded!"
        }
      />

      <section className="section-gap">
        <div className="container">
          <div className="row justify-content-center">
            {data.wpgraphql.page.content && (
              <div
                className="col-md-12 header-text mb-4 px-sm-5"
                dangerouslySetInnerHTML={{
                  __html: data.wpgraphql.page.content,
                }}
              />
            )}
          </div>
        </div>
      </section>
          <section className="section-gap referral-coupon light-gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h2 className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: 
                        data.wpgraphql.page.homewonerreferral.referralCoupon.sectionTitle,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.wpgraphql.page.homewonerreferral.referralCoupon
                          .sectionContent,
                    }}
                  />
                  <a
                    href={`${baseUrl}/Homeowner-Referral-Coupon.pdf`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="btn btn-lg btn-primary my-5"
                  >
                    Print / Save Coupon
                  </a>
              </div>
              <div className="col-md-6">
                <img
                    src={
                      data.wpgraphql.page.homewonerreferral.referralCoupon
                        .sectionImage.sourceUrl
                    }
                    className="d-md-block mx-auto"
                    alt={
                      data.wpgraphql.page.homewonerreferral.referralCoupon
                        .sectionImage.altText
                    }
                  />
              </div>
            </div>
          </div>
        </section>

      <section className="section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="mb-3 text-center">
                <h2
                  className="my-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.homewonerreferral.howItWorks
                        .sectionTitle,
                  }}
                />
                <img
                  src={
                    data.wpgraphql.page.homewonerreferral.howItWorks
                      .sectionImage.sourceUrl
                  }
                  className="d-none d-md-block mx-auto"
                  alt={
                    data.wpgraphql.page.homewonerreferral.howItWorks
                      .sectionImage.altText
                  }
                />
                <img
                  src={
                    data.wpgraphql.page.homewonerreferral.howItWorks
                      .mobileViewImage.sourceUrl
                  }
                  className="d-md-none mx-auto"
                  alt={
                    data.wpgraphql.page.homewonerreferral.howItWorks
                      .mobileViewImage.altText
                  }
                />
              </div>
              <div className="mb-3 text-center">
                <h3
                  className="h2 mt-5 mb-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.homewonerreferral.getProjectStarted
                        .sectionTitle,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.homewonerreferral.getProjectStarted
                        .sectionContent,
                  }}
                />
                <a
                  href="/homeowner-referral/#contractor_form"
                  className="btn btn-lg btn-primary mt-5"
                >
                  Refer a Contractor
                </a>
              </div>
              <div className="mb-3 pt-3">
                <h3
                  className="h2 mt-5 mb-4 text-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.homewonerreferral.unsecuredHomeLoans
                        .sectionTitle,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.homewonerreferral.unsecuredHomeLoans
                        .sectionContent,
                  }}
                  className="unsecuredhomeloan"
                />
              </div>
              <hr className="my-5" />
              <div className="mb-3 mx-md-5 px-md-5" id="contractor_form">
                <div
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.wpgraphql.page.referralform.contractorsInfoForm
                        .formHeading,
                  }}
                />
                <HubspotForm
                  portalId="381510"
                  formId="c2c00221-fc69-48c4-bbd6-2135ae5bfcae"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                <div className="homeowner-form">
                  <div
                    className="footer col-12 text-center"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.wpgraphql.page.referralform.userInfoForm.footer,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default HomeOwnerReferral

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
        homewonerreferral {
          fieldGroupName
          getProjectStarted {
            fieldGroupName
            sectionContent
            sectionTitle
          }
          referralCoupon {
            fieldGroupName
            sectionTitle
            sectionContent
            sectionImage {
              sourceUrl
              altText
            }
          }
          howItWorks {
            fieldGroupName
            sectionTitle
            sectionImage {
              altText
              sourceUrl
            }
            mobileViewImage {
              sourceUrl
              altText
            }
          }
          unsecuredHomeLoans {
            fieldGroupName
            sectionContent
            sectionTitle
          }
        }
        referralform {
          fieldGroupName
          contractorsInfoForm {
            fieldGroupName
            formHeading
            formField {
              fieldGroupName
              fieldName
              fieldTitle
              fieldType
            }
          }
          userInfoForm {
            fieldGroupName
            topHeading
            formField {
              fieldGroupName
              fieldName
              fieldTitle
              fieldType
            }
            footer
          }
        }
      }
    }
  }
`
